<template>
  <section>
    <validation-observer
      #default="{ handleSubmit, invalid, pristine }"
      ref="refFormObserver"
    >
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        class="border"
      >
        <template #header>
          <div class="d-flex align-items-center justify-content-between w-100">
            <h5 class="m-0">
              {{ $t('employee.empInfo') }}
            </h5>

            <div class="d-flex gap-2">
              <b-button
                v-if="false"
                variant="info"
                class="mr-1"
                @click="showRoleModal($event.target)"
              >
                <span class="">{{ $t('employee.role') }}</span>
              </b-button>

              <b-button
                v-if="canResetPaymentPassword"
                variant="outline-danger"
                @click="handleResetPaymentPassword"
              >
                <!-- @click="showRoleModal($event.target)" -->
                <span class="">{{ $t('myAccount.paymentPassword.reset') }}</span>
              </b-button>

              <b-button
                v-if="readonly"
                variant="info"
                :disabled="!canAccess('employee.updateEmployee')"
                @click="handleEdit"
              >
                <span class="">{{ $t('edit') }}</span>
              </b-button>
              <b-button
                v-else
                variant="outline-info"
                :disabled="!canAccess('employee.updateEmployee')"
                @click="handleCancelEdit"
              >
                <span class="">{{ $t('cancelEdit') }}</span>
              </b-button>
            </div>

            <EmployeesRoleModal
              v-if="employeeData"
              :employee-data="employeeData"
              :is-show-modal="isShowModalEmployeesRole"
            />
          </div>
        </template>

        <b-card-body class="p-0 pt-1">
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>
              <!-- Username -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="`${$t('employee.userName')}`"
                  label-for="user-name"
                >
                  <b-form-input
                    id="user-name"
                    :value="employeeToEdit.username.toUpperCase()"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <!-- Employee Code -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="`${$t('employee.empCode')}`"
                  label-for="emp-code"
                >
                  <b-form-input
                    id="emp-code"
                    v-model="employeeToEdit.employeeCode"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <!-- Last Name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Last Name"
                  rules="required|max:30"
                >
                  <b-form-group label-for="lastName">
                    <template #label>
                      {{ $t('employee.lastName') }}
                      <span class="text-danger">{{ isEditActive }}</span>
                    </template>
                    <b-form-input
                      id="lastName"
                      v-model="employeeToEdit.lastName"
                      :disabled="readonly"
                      :placeholder="$t('employee.placeholderLastName')"
                      maxlength="30"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- First Name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="First Name"
                  rules="required"
                >
                  <b-form-group label-for="firstName">
                    <template #label>
                      {{ $t('employee.firstName') }}
                      <span class="text-danger">{{ isEditActive }}</span>
                    </template>
                    <b-form-input
                      id="firstName"
                      v-model="employeeToEdit.firstName"
                      :disabled="readonly"
                      :placeholder="$t('employee.placeholderFirstName')"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Phone Number -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.phoneNumber')"
                  :rules="`required|phoneNumber`"
                >
                  <!-- Bo unique: |isUnique:employee,phonenumber,${employeeData.phoneNumber} -->
                  <b-form-group label-for="phoneNumber">
                    <template #label>
                      {{ $t('employee.phoneNumber') }}
                      <span class="text-danger">{{ isEditActive }}</span>
                    </template>
                    <b-form-input
                      id="phoneNumber"
                      v-model="employeeToEdit.phoneNumber"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :disabled="readonly"
                      lazy-formatter
                      :formatter="trimInput"
                      :debounce="300"
                      :placeholder="$t('employee.placeholderPhoneNumber')"
                    />
                    <!-- maxlength="10" -->
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Email -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.email')"
                  :rules="`required|email`"
                >
                  <!-- Bỏ unique: |isUnique:employee,email,${employeeData.emailAddress} -->
                  <b-form-group label-for="email">
                    <template #label>
                      {{ $t('employee.email') }}
                      <span class="text-danger">{{ isEditActive }}</span>
                    </template>
                    <b-form-input
                      id="email"
                      v-model="employeeToEdit.emailAddress"
                      maxlength="50"
                      :disabled="readonly"
                      :placeholder="$t('employee.placeholderEmail')"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      :debounce="300"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Gender -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.gender')"
                  rules=""
                >
                  <b-form-group
                    :label="$t('employee.gender')"
                    label-for="gender-type"
                  >
                    <v-select
                      v-model="employeeToEdit.gender"
                      label="label"
                      :options="genderOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :disabled="readonly"
                      input-id="gender-type"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Type -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.employeeType')"
                  rules="required"
                >
                  <b-form-group
                    label-for="employee-type"
                    :state="getValidationState(validationContext)"
                  >
                    <template #label>
                      {{ $t('employee.employeeType') }}
                      <span class="text-danger">{{ isEditActive }}</span>
                    </template>
                    <v-select
                      v-model="employeeToEdit.type"
                      :options="isADM ? typeOfEmployeeOptions : typeOfEmployeeOptionsKhacADM"
                      :reduce="val => val.value"
                      :clearable="false"
                      :disabled="readonly || (isADM && (meData.id === employeeData.id))"
                      input-id="employee-type"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- agency -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Agency"
                  rules="required"
                >
                  <b-form-group
                    label-for="agency"
                    :state="getValidationState(validationContext)"
                  >
                    <template #label>
                      {{ $t('employee.agencies') }}
                      <span class="text-danger">{{ isEditActive }}</span>
                    </template>
                    <v-select
                      v-model="employeeToEdit.agency"
                      :options="agenciesManagerList"
                      :reduce="val => val.id"
                      :clearable="false"
                      disabled
                      input-id="agency"
                      label="agencyName"
                      @open="openAgenciesManager"
                      @search="searchAgenciesManager"
                    >
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.agencyName }}
                          </span>
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span class="font-weight-bold d-block text-nowrap text-uppercase">
                            {{ data.agencyCode }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <small
                  v-if="employeeToEdit.agency.parentAgency"
                  class="font-italic"
                >
                  Thuộc đại lý:
                  <span class="text-uppercase font-weight-bolder font-normal">
                    {{ employeeToEdit.agency.parentAgency.agencyCode }}
                  </span>
                  ({{ employeeToEdit.agency.parentAgency.agencyName }})
                </small>
              </b-col>

              <!-- ANCHOR BookerType -->
              <b-col
                v-if="employeeToEdit.type === 'BE'"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.bookerType')"
                  rules=""
                >
                  <b-form-group
                    label-for="booker-type"
                    :state="getValidationState(validationContext)"
                  >
                    <template #label>
                      {{ $t('employee.bookerType') }}
                      <!-- <span class="text-danger">{{ isEditActive }}</span> -->
                    </template>
                    <v-select
                      v-model="employeeToEdit.bookerType"
                      :options="flightTypeOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      :disabled="readonly"
                      input-id="booker-type"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR ScheduleCode -->
              <b-col
                v-if="['BE', 'SE'].includes(employeeToEdit.type) && isRoleF1"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="employeeToEdit.type === 'BE' ? $t('employee.scheduleCodeBE') : $t('employee.scheduleCodeSE')"
                >
                  <b-form-group label-for="scheduleCode">
                    <template #label>
                      {{ employeeToEdit.type === 'BE' ? $t('employee.scheduleCodeBE') : $t('employee.scheduleCodeSE') }}
                    </template>
                    <b-form-input
                      id="scheduleCode"
                      v-model="employeeToEdit.scheduleCode"
                      v-remove-non-numeric-chars.allNumber
                      number
                      :name="employeeToEdit.type === 'BE' ? $t('employee.scheduleCodeBE') : $t('employee.scheduleCodeSE')"
                      :disabled="readonly"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :placeholder="$t('employee.placeholderScheduleCode')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR IDBooker: ADM F1 can update for employee -->
              <b-col
                v-if="isADM && isRoleF1 && (employeeToEdit.type === 'BE' || (isOpenIdBookerForSale && employeeToEdit.type === 'SE'))"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.bookerId')"
                  rules="bookerId|min:5"
                >
                  <b-form-group label-for="bookerId">
                    <template #label>
                      {{ $t('employee.bookerId') }}
                    </template>
                    <b-form-input
                      id="bookerId"
                      v-model="employeeToEdit.bookerId"
                      :name="$t('employee.bookerId')"
                      :disabled="readonly"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :formatter="trimAllInputAndUpper"
                      :placeholder="$t('employee.placeholderBookerId')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Telegram ID -->

              <!-- Phone Number -->
              <b-col
                v-if="isShowEmployeeTelegramId"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.telegramId')"
                  rules=""
                >
                  <b-form-group label-for="telegramId">
                    <template #label>
                      <HStack>
                        <IAmIcon
                          icon="telegram"
                          size="16"
                          color="#40B3E0"
                        />
                        <div>
                          {{ $t('employee.telegramId') }}
                        </div>
                      </HStack>
                    </template>
                    <b-form-input
                      id="telegramId"
                      v-model="employeeToEdit.telegramId"
                      v-remove-non-numeric-chars.allNumber
                      :state="getValidationState(validationContext) === false ? false : null"
                      :disabled="readonly"
                      lazy-formatter
                      :formatter="trimInput"
                      :debounce="300"
                      :placeholder="$t('employee.placeholderTelegramId')"
                    />
                    <!-- maxlength="10" -->
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
      <div class="d-flex justify-content-center">
        <b-button
          variant="secondary"
          class="mb-1 mr-50"
          @click="onCancel"
        >
          {{ $t('back') }}
        </b-button>
        <b-button
          v-if="!readonly"
          variant="info"
          class="mb-1 ml-50"
          :disabled="invalid || pristine"
          @click="handleSubmit(updatedEmployeeHandle(true))"
        >
          {{ $t('save') }}
        </b-button>
      </div>
    </validation-observer>
  </section>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardBody, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from 'vue'

import router from '@/router'
import {
  genderOptions, typeOfEmployeeOptions, typeOfEmployeeOptionsKhacADM, flightTypeOptions,
} from '@/constants/selectOptions'
import store from '@/store'
import { apiEmployee } from '@/api'
import env from '@/libs/env'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import { trimInput, trimAllInputAndUpper } from '@core/comp-functions/forms/formatter-input'

import useEmployeeHandle from '@employee/useEmployeeHandle'

import {
  required, email, min, isUnique, phoneNumber, bookerId,
} from '@validations'
import useToast from '@useToast'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    vSelect,

    EmployeesRoleModal: () => import('./EmployeesRoleModal.vue'),

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()
    const employeeToEdit = ref(JSON.parse(JSON.stringify(props.employeeData)))
    const resetEmployeeToEdit = () => {
      employeeToEdit.value = JSON.parse(JSON.stringify(props.employeeData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetEmployeeToEdit)

    const readonly = ref(true)

    const {
      updateEmployee,
      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
    } = useEmployeeHandle()

    const onCancel = () => {
      resetForm()
      router.go(-1)
    }

    const meData = computed(() => store.getters['userStore/getMeData'])
    const isADM = computed(() => meData.value?.type === 'ADM')
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const getMeDataType = computed(() => store.getters['userStore/getMeDataType'])
    const getAgencyDataId = computed(() => store.getters['userStore/getAgencyDataId'])

    const isEditActive = computed(() => {
      const val = readonly.value === true ? '' : '(*)'
      return val
    })

    function handleEdit() {
      readonly.value = false
    }

    function updatedEmployeeHandle(next = false) {
      const dataToUpdate = getDifference(employeeToEdit.value, props.employeeData)
      updateEmployee(employeeToEdit.value, {
        ...dataToUpdate,
      })
        .then(() => {
          emit('refetch-data')
          if (next) {
            router.push({ name: 'apps-employees-list' })
          }
        })
        .catch()
    }

    function handleCancelEdit() {
      resetForm()
      readonly.value = true
    }

    const isShowModalEmployeesRole = ref(false)
    function showRoleModal(event) {
      isShowModalEmployeesRole.value = true
      this.$root.$emit('bv::show::modal', 'modal-handle-employees-role', event)
    }

    const canResetPaymentPassword = computed(() => {
      const employeeType = employeeToEdit.value.type
      const employeeId = employeeToEdit.value.id

      // employee phải bật MKTT
      if (!props.employeeData?.enablePaymentPassword) return false

      // ADM F1: được update tất cả user && OPE F1: được update tất cả user trừ ADM và OPE khác F1
      if (
        (getMeDataType.value === 'ADM'
        || (getMeDataType.value === 'OPE'
          && (!['ADM', 'OPE'].includes(employeeType) || employeeId === meData.value.id)
        ))
      && isRoleF1.value) return true
      // OTHER F1: được update tất cả cấp dưới và chính nó
      if (employeeId === meData.value.id) return true
      return (props.employeeData.agency?.parentAgency?.id === getAgencyDataId.value || props.employeeData.agency?.id === getAgencyDataId.value)
    })

    function handleResetPaymentPassword() {
      this.$bvModal
        .msgBoxConfirm(this.$t('myAccount.paymentPassword.resetConfirm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            apiEmployee.resetPaymentPassword(props.employeeData.id)
              .then(async res => {
                await store.dispatch('userStore/fetchMeData')
                const { username, newPassword } = res
                Vue.swal({
                  title: this.$t('messagesList.success'),
                  html: `
                  <div class="text-20px font-weight-bolder">
                    ${this.$t('myAccount.paymentPassword.updateSuccess')}
                    <div class="text-16px">
                      Mật khẩu thanh toán mặc định của tài khoản <span class='text-uppercase text-20px font-weight-bolder'>${username}</span> là <code class='px-50 text-20px font-weight-bolder'>${newPassword}</code>
                    </div>
                  </div>`,
                  icon: 'success',
                  showCancelButton: true,
                  showConfirmButton: true,
                  allowEscapeKey: true,
                  allowOutsideClick: false,
                  cancelButtonText: this.$t('close'),
                  confirmButtonText: this.$t('copy'),
                  customClass: {
                    title: 'text-20px font-weight-bolder text-success',
                    cancelButton: 'btn btn-flat-danger ml-50',
                    confirmButton: 'btn btn-warning mr-50',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    navigator.clipboard.writeText(newPassword)
                    Vue.swal({
                      icon: 'success',
                      title: 'Đã copy!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
              })
              .catch(() => {
                toastError({
                  title: 'messagesList.error',
                  content: 'myAccount.paymentPassword.updateError',
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    return {
      employeeToEdit,
      // onRemoveAvatar,
      updatedEmployeeHandle,
      onCancel,
      handleEdit,
      handleCancelEdit,
      // confirmDelete,
      // updateDeactive,
      refFormObserver,

      // resolveEmployeeTypeTitle,
      // resolveEmployeeTypeVariant,
      avatarText,
      typeOfEmployeeOptions,
      typeOfEmployeeOptionsKhacADM,
      genderOptions,
      readonly,
      bookerId,

      getValidationState,
      required,
      email,
      min,
      isUnique,
      isADM,
      phoneNumber,

      isEditActive,
      // Upload avatar
      // refInputEl,
      // previewEl,
      // inputImageFormData,
      // onRemoveAvatar,
      trimInput,
      trimAllInputAndUpper,

      isShowModalEmployeesRole,
      showRoleModal,

      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
      // for BookerType
      flightTypeOptions,
      meData,
      canResetPaymentPassword,
      handleResetPaymentPassword,
      isRoleF1,
      isOpenIdBookerForSale: env.isOpenIdBookerForSale,
      isShowEmployeeTelegramId: env.isShowEmployeeTelegramId,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
