<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <component :is="employeeData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="employeeData === undefined"
      >
        <h4 class="alert-heading">
          {{ $t('employee.errFetch') }}
        </h4>
        <div class="alert-body">
          {{ $t('employee.errFindId_1') }}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-employees-list'}"
          >
            {{ $t('employee.errFindId_2') }}
          </b-link>
          {{ $t('employee.errFindId_3') }}
        </div>
      </b-alert>

      <b-tabs
        v-if="employeeData"
        pills
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('employee.information') }}</span>
          </template>
          <employees-edit-tab-account :employee-data="employeeData" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('employee.customers') }}</span>
          </template>
          <employees-edit-tab-customer :employee-data="employeeData" />
        </b-tab>

        <b-tab :disabled="!(['SE','BE','KTV'].includes(employeeData.type))">
          <template #title>
            <feather-icon
              icon="ListIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('employee.agencies') }}</span>
          </template>
          <employees-edit-tab-agencies :managed-agencies="employeeData.managedAgencies.map(item => item.agency)" />
        </b-tab>
      </b-tabs>
    </component>
  </b-overlay>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

import employeeStoreModule from '@employee/employeeStoreModule'
// eslint-disable-next-line import/extensions
import EmployeesEditTabAgencies from '@employee/employees-edit/EmployeesEditTabAgencies'

import EmployeesEditTabAccount from './EmployeesEditTabAccount.vue'
import EmployeesEditTabCustomer from './EmployeesEditTabCustomer.vue'

export default {
  components: {
    EmployeesEditTabAgencies,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    EmployeesEditTabAccount,
    EmployeesEditTabCustomer,
  },
  setup() {
    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
    })

    const employeeData = ref(null)
    const loading = ref(true)
    store.dispatch('app-employee/fetchEmployeeById', { id: router.currentRoute.params.id })
      .then(response => { employeeData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          employeeData.value = undefined
        }
      }).finally(() => {
        loading.value = false
      })

    return {
      employeeData,
      loading,
    }
  },
}
</script>

<style>

</style>
